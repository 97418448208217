<template>
  <div class="home">

    <div v-if="success == true" class="success-wrap">
      <p><span class="material-icons" style="font-size:70px;color:#FF9000;">check_circle</span></p>
      <h1>Buchung erfolgreich durchgeführt.</h1>
      <div class="selected-vehicle">
        <p v-if="vehicle.details.image" class="vehicle-image">
          <img :src="vehicle.details.image" width="240" height="140">
        </p>
        <p v-else class="vehicle-image">
          <img src="https://placehold.co/240x140?text=Auto+Bild+Platzhalter">
        </p>
        <h3>{{ vehicle.details.name }}</h3>
        <p>{{ vehicle.details.seats }} Sitze | {{ vehicle.details.doors }} Türen | {{ vehicle.details.drive }}</p>
      </div>
    </div>

    <div v-else>

      <div class="step-wizard">
        <div class="row row-gutter-20">
          <div class="col-6">
            <div v-if="reservation.current_step == 1" class="step-wrap active">
              <span class="step">1</span>
              <h5>Zeitraum</h5>
              <p>Bestimmen Sie Zeitraum und Ort</p>
            </div>
            <div v-else class="step-wrap">
              <span v-if="period.completed" class="step">✓</span>
              <span v-if="period.completed == false" class="step">1</span>
              <h5>Zeitraum</h5>
              <p @click="change_step(1)" v-if="reservation.current_step > 1" class="change-step">
                <i class="material-icons">edit</i><span>Ändern</span>
              </p>
              <p>Bestimmen Sie Zeitraum und Ort</p>
            </div>
          </div>
          <div class="col-6">
            <div v-if="reservation.current_step == 2" class="step-wrap active">
              <span class="step">2</span>
              <h5>Fahrzeug</h5>
              <p>Wählen Sie Ihr Wunschfahrzeug</p>
            </div>
            <div v-else class="step-wrap">
              <span v-if="vehicle.completed" class="step">✓</span>
              <span v-if="vehicle.completed == false" class="step">2</span>
              <h5>Fahrzeug</h5>
              <p @click="change_step(2)" v-if="reservation.current_step > 2" class="change-step">
                <i class="material-icons">edit</i><span>Ändern</span>
              </p>
              <p>Wählen Sie Ihr Wunschfahrzeug</p>
            </div>
          </div>
          <div class="col-6">
            <div v-if="reservation.current_step == 3" class="step-wrap active">
              <span v-if="extras.completed" class="step">✓</span>
              <span v-if="extras.completed == false" class="step">3</span>
              <h5>Extras</h5>
              <p>Wählen Sie Ihre Versicherung & Extras</p>
            </div>
            <div v-else class="step-wrap">
              <span class="step">3</span>
              <h5>Extras</h5>
              <p @click="change_step(3)" v-if="reservation.current_step > 3" class="change-step">
                <i class="material-icons">edit</i><span>Ändern</span>
              </p>
              <p>Wählen Sie Ihre Versicherung & Extras</p>
            </div>
          </div>
          <div class="col-6">
            <div v-if="reservation.current_step == 4" class="step-wrap active">
              <span v-if="personal_data.completed" class="step">✓</span>
              <span v-if="personal_data.completed == false" class="step">4</span>
              <h5>Ihre Daten</h5>
              <p>Persönliche Daten und Bestellung</p>
            </div>
            <div v-else class="step-wrap">
              <span class="step">4</span>
              <h5>Ihre Daten</h5>
              <p>Persönliche Daten und Bestellung</p>
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-body">

        <div v-if="reservation.current_step == 1">
          <h2>Abholung / Rückgabe</h2>

          <div class="form-wrap select-wrap">
            <label for="pickup_location">Abholung und Rückgabe in</label>
            <select v-model="pickup_location" id="pickup_location">
              <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="row row-gutter-20">
                <div class="col-12">

                  <div class="form-wrap select-wrap">
                    <label for="pickup_date">Abholung Datum</label>
                    <v-date-picker v-model="period.pickup_date" :min-date='new Date()' :disabled-dates='{ weekdays: [1] }' :first-day-of-week="2" locale="de">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="form-input"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>

                </div>
                <div class="col-12">

                  <div class="form-wrap select-wrap">
                    <label for="pickup_time">Abholung Uhrzeit</label>
                    <select v-model="period.pickup_time" id="pickup_time">
                      <option v-for="hour in period.pickup_hours" v-bind:value="hour.hour_time_val" v-bind:key="hour.id">{{ hour.hour_time_formatted }}</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row row-gutter-20">
                <div class="col-12">

                  <div class="form-wrap select-wrap">
                    <label for="return_date">Rückgabe Datum</label>
                    <v-date-picker v-model="period.return_date" :min-date='this.period.pickup_date' :disabled-dates='{ weekdays: [1] }' :first-day-of-week="2" locale="de">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="form-input"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>

                </div>
                <div class="col-12">

                  <div class="form-wrap select-wrap">
                    <label for="return_time">Rückgabe Uhrzeit</label>
                    <select v-model="period.return_time" id="return_time">
                      <option v-for="hour in period.return_hours" v-bind:value="hour.hour_time_val" v-bind:key="hour.id">{{ hour.hour_time_formatted }}</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <p><a @click="set_period" class="button button-orange">Jetzt Fahrzeug auswählen ›</a></p>

        </div>

        <div v-else-if="reservation.current_step == 2">

          <h2>Verfügbare Fahrzeuge</h2>

          <p style="text-align:center"><strong>Abholung:</strong> {{ reservation.period.pickup_date_formatted }} – {{ reservation.period.pickup_time }}:00 Uhr | <strong>Rückgabe:</strong> {{ reservation.period.return_date_formatted }} – {{ reservation.period.return_time }}:00 Uhr | <strong>Mietdauer:</strong> {{ reservation.period.duration }} Tage</p>

          <ul class="car-categories">
            <div v-if="vehicle_categories.length <= 3">
              <li class="alert alert-info cf" style="margin-top:20px">
                <p style="margin:0;line-height:1.3"><strong style="display:block;margin-bottom:8px;font-size:16px">Hinweis:</strong>Weitere Fahrzeuge sind womöglich im Umkreis an einem anderen Standort verfügbar und können auch an diesem Standort zur Verfügung gestellt werden.<br>Bitte rufen Sie uns hierfür einfach an (0157 836 661 61) oder schreiben Sie uns gerne eine E-Mail (info@intime-autovermietung.de).</p>
              </li>
            </div>
            <li v-for="category in vehicle_categories" v-bind:key="category.id" class="cf">
              <p v-if="category.image" class="vehicle-image">
                <img :src="category.image" width="400" height="auto">
              </p>
              <p v-else class="vehicle-image">
                <img src="https://placehold.co/400x240?text=Auto+Bild+Platzhalter">
              </p>
              <div class="vehicle-body">
                <h3>{{ category.name }} <small>{{ category.tagline }}</small></h3>
                <p v-if="vehicle.min_age">Mindestalter: {{ vehicle.min_age }} Jahre</p>
                <div class="vc-w" style="margin-top:20px">
                  <span class="material-icons">people</span>
                  <span>{{ category.seats }} Sitze</span>
                </div>
                <div class="vc-w">
                  <span class="material-icons">sensor_door</span>
                  <span>{{ category.doors }} Türen</span>
                </div>
                <div class="vc-w">
                  <span class="material-icons">work</span>
                  <span>{{ category.luggage }} Gepäck</span>
                </div>
                <div class="vc-w">
                  <span class="material-icons">directions_car</span>
                  <span v-if="category.drive == 'manual'">Gangschaltung</span>
                  <span v-if="category.drive == 'automatic'">Automatik</span>
                </div>
              </div>
              <p class="vehicle-cta">
                <span>Jetzt buchen ›</span>
                <a @click="set_vehicle_category(category)" class="button button-orange">{{ (category.price_calculated*reservation.period.duration).toFixed(2) }} € →</a>
                <span style="display:block;text-align:right;margin-top:8px;opacity:0.5">{{ parseFloat(category.price_calculated).toFixed(2) }} € / Tag</span>
              </p>
            </li>
            <div v-if="vehicle_categories.length > 3">
              <li class="alert alert-info cf">
                <p style="margin:0;line-height:1.3"><strong style="display:block;margin-bottom:8px;font-size:16px">Hinweis:</strong>Weitere Fahrzeuge sind womöglich im Umkreis an einem anderen Standort verfügbar und können auch an diesem Standort zur Verfügung gestellt werden.<br>Bitte rufen Sie uns hierfür einfach an (0157 836 661 61) oder schreiben Sie uns gerne eine E-Mail (info@intime-autovermietung.de).</p>
              </li>
            </div>
          </ul>

        </div>

        <div v-else-if="reservation.current_step == 3">

          <div class="row row-gutter-20">
            <div class="col-8">

              <div class="selected-vehicle">
                <p v-if="vehicle.details.image" class="vehicle-image">
                  <img :src="vehicle.details.image" width="240" height="140">
                </p>
                <p v-else class="vehicle-image">
                  <img src="https://placehold.co/240x140?text=Auto+Bild+Platzhalter">
                </p>
                <h3>{{ vehicle.details.name }}</h3>
                <p>{{ vehicle.details.seats }} Sitze | {{ vehicle.details.doors }} Türen | <span v-if="vehicle.details.drive == 'manual'">Gangschaltung</span><span v-if="vehicle.details.drive == 'automatic'">Automatik</span></p>
                <p><strong>Abholung:</strong><br> {{ reservation.period.pickup_date_formatted }} – {{ reservation.period.pickup_time }}:00 Uhr</p>
                <p><strong>Rückgabe:</strong><br> {{ reservation.period.return_date_formatted }} – {{ reservation.period.return_time }}:00 Uhr</p>
                <p><strong>Mietdauer:</strong><br> {{ reservation.period.duration }} Tage</p>
                <p style="margin-bottom:0"><strong>Kosten Fahrzeug:</strong><br>{{ parseFloat(reservation.vehicle.details.price_calculated*reservation.period.duration).toFixed(2) }} €<br><small>({{ parseFloat(reservation.vehicle.details.price_calculated).toFixed(2) }} € / Tag)</small></p>
              </div>

            </div>
            <div class="col-16">

              <h2>Versicherung & Extras</h2>

              <h3>Versicherung</h3>

              <div v-for="extra in available_extras.insurances" v-bind:key="extra.id">
                <label :for="extra.id" @click="set_insurance(extra)" class="extra-wrap cf">
                  <input type="radio" :id="extra.id" :value="extra.id" v-model="extras.insurance">
                  <div class="extra-wrap-body">
                    <h4>{{ extra.feature.name }}</h4>
                    <p>{{ extra.feature.description }}</p>
                  </div>
                  <p class="price">
                    {{ parseFloat(extra.feature.price).toFixed(2) }} €
                    <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                    <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                  </p>
                </label>
              </div>

              <h3>Kilometer</h3>

              <div v-for="extra in available_extras.kilometer" v-bind:key="extra.id">
                <label :for="extra.id" @click="set_kilometer(extra)" class="extra-wrap cf">
                  <input type="radio" :id="extra.id" :value="extra.id" v-model="extras.kilometer">
                  <div class="extra-wrap-body">
                    <h4>{{ extra.feature.name }}</h4>
                    <p>{{ extra.feature.description }}</p>
                  </div>
                  <p class="price">
                    {{ parseFloat(extra.feature.price).toFixed(2) }} €
                    <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                    <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                  </p>
                </label>
              </div>

              <h3>Extras</h3>

              <div v-for="extra in available_extras.extras" v-bind:key="extra.id">
                <label :for="extra.id" class="extra-wrap cf">
                  <input type="checkbox" @click="set_extrass()" :id="extra.id" :value="extra.id" v-model="extras.extras">
                  <div class="extra-wrap-body">
                    <h4>{{ extra.feature.name }}</h4>
                    <p>{{ extra.feature.description }}</p>
                  </div>
                  <p class="price">
                    {{ parseFloat(extra.feature.price).toFixed(2) }} €
                    <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                    <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                  </p>
                </label>
              </div>

              <p><a @click="set_extras" class="button button-orange">Weiter ›</a></p>

            </div>
          </div>
        </div>

        <div v-else-if="reservation.current_step == 4">

          <div class="row row-gutter-20">
            <div class="col-8">

              <div class="selected-vehicle">
                <p v-if="reservation.vehicle.details.image" class="vehicle-image">
                  <img :src="reservation.vehicle.details.image" width="240" height="140">
                </p>
                <p v-else class="vehicle-image">
                  <img src="https://placehold.co/240x140?text=Auto+Bild+Platzhalter">
                </p>
                <h3>{{ reservation.vehicle.details.name }}</h3>
                <p>{{ reservation.vehicle.details.seats }} Sitze | {{ reservation.vehicle.details.doors }} Türen | <span v-if="reservation.vehicle.details.drive == 'manual'">Gangschaltung</span><span v-if="reservation.vehicle.details.drive == 'automatic'">Automatik</span></p>
                <p><strong>Abholung:</strong><br> {{ reservation.period.pickup_date_formatted }} – {{ reservation.period.pickup_time }}:00 Uhr</p>
                <p><strong>Rückgabe:</strong><br> {{ reservation.period.return_date_formatted }} – {{ reservation.period.return_time }}:00 Uhr</p>
                <p style="margin-bottom:0"><strong>Mietdauer:</strong><br> {{ reservation.period.duration }} Tage</p>
              </div>

            </div>
            <div class="col-16">

              <h2>Ihre Daten</h2>

              <p style="font-size:12px">Felder mit <strong style="color:red">*</strong> sind Pflichtfelder.</p>

              <h3>Persönliche Daten</h3>

              <div class="form-wrap">
                <label for="company">Unternehmen</label>
                <input v-model="personal_data.company" id="company" class="form-input">
              </div>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="first_name">Vorname <small style="color:red">*</small></label>
                    <input v-model="personal_data.first_name" id="first_name" class="form-input">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="last_name">Nachname <small style="color:red">*</small></label>
                    <input v-model="personal_data.last_name" id="last_name" class="form-input">
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-16">
                  <div class="form-wrap">
                    <label for="street_name">Straße <small style="color:red">*</small></label>
                    <input v-model="personal_data.street_name" class="form-input" type="text" name="street_name" id="street_name">
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="street_number">Hausnummer <small style="color:red">*</small></label>
                    <input v-model="personal_data.street_number" class="form-input" type="text" name="street_number" id="street_number">
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="zip_code">Postleitzahl <small style="color:red">*</small></label>
                    <input v-model="personal_data.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                  </div>
                </div>
                <div class="col-16">
                  <div class="form-wrap">
                    <label for="city">Stadt <small style="color:red">*</small></label>
                    <input v-model="personal_data.city" class="form-input" type="text" name="city" id="city">
                  </div>
                </div>
              </div>

              <div class="form-wrap">
                <label for="birth_date">Geburtsdatum <small style="color:red">*</small></label>
                <input v-model="personal_data.birth_date" class="form-input" type="text" name="birth_date" id="birth_date" placeholder="01.01.1990">
              </div>

              <h3>Kontaktmöglichkeiten</h3>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="email">E-Mail-Adresse <small style="color:red">*</small></label>
                    <input v-model="personal_data.email" id="email" class="form-input">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="phone">Telefonnummer <small style="color:red">*</small></label>
                    <input v-model="personal_data.phone_number" id="phone" class="form-input">
                  </div>
                </div>
              </div>

              <h3>Kostenübersicht</h3>

              <table class="table" style="border: 1px solid #dee2e6;margin-bottom:20px">
                <thead>
                  <tr>
                    <th width="50%">Beschreibung</th>
                    <th width="50%" style="text-align:right">Kosten</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fahrzeugmiete für {{ reservation.period.duration }} Tage</td>
                    <td style="text-align:right">{{ parseFloat(reservation.vehicle.details.price_calculated*reservation.period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr>
                    <td>Versicherung ({{ reservation.extras.pricing.insurance.name }})</td>
                    <td v-if="reservation.extras.pricing.insurance.price_type == 'once'" style="text-align:right">{{ parseFloat(reservation.extras.pricing.insurance.price).toFixed(2) }} €</td>
                    <td v-if="reservation.extras.pricing.insurance.price_type == 'per_day'" style="text-align:right">{{ parseFloat(reservation.extras.pricing.insurance.price*reservation.period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr>
                    <td>Kilometer ({{ reservation.extras.pricing.kilometer.name }})</td>
                    <td v-if="reservation.extras.pricing.kilometer.price_type == 'once'" style="text-align:right">{{ parseFloat(reservation.extras.pricing.kilometer.price).toFixed(2) }} €</td>
                    <td v-if="reservation.extras.pricing.kilometer.price_type == 'per_day'" style="text-align:right">{{ parseFloat(reservation.extras.pricing.kilometer.price*reservation.period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr v-for="extra in reservation.extras.pricing.extras" v-bind:key="extra.name">
                    <td>{{ extra.name }}</td>
                    <td v-if="extra.price_type == 'once'" style="text-align:right">{{ parseFloat(extra.price).toFixed(2) }} €</td>
                    <td v-if="extra.price_type == 'per_day'" style="text-align:right">{{ parseFloat(extra.price*reservation.period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr style="background:#F6F9FD;color:#495057;border-top:3px solid rgb(222, 226, 230)">
                    <td><strong>Gesamtbetrag (Netto)</strong></td>
                    <td style="text-align:right">{{ reservation.extras.pricing.sum_net }} €</td>
                  </tr>
                  <tr style="background:#F6F9FD;color:#495057">
                    <td><strong>+ {{ tax_rate }}% MwSt</strong></td>
                    <td style="text-align:right">{{ reservation.extras.pricing.sum_tax }} €</td>
                  </tr>
                  <tr style="background:#444;color:#fff">
                    <td><strong>GESAMTBETRAG</strong></td>
                    <td style="text-align:right"><strong>{{ reservation.extras.pricing.sum_gross }} €</strong></td>
                  </tr>
                </tbody>
              </table>

              <div class="alert alert-kaution" style="margin-bottom:40px">
                Bitte bringen Sie zusätzlich bei Abholung eine Kaution in Höhe von <strong>200 EUR</strong> und Ihren Führerschein mit.
              </div>

              <h3>Bezahlmethode</h3>

              <div class="payment-options row row-gutter-20" style="margin-bottom:15px">
                <!--<div class="option-wrap col-6">
                  <label for="sepa" class="payment-select">
                    <p><input type="radio" id="sepa" value="sepa" v-model="personal_data.payment_option"></p>
                    <p><img src="../../assets/images/methods/sepa.png" width="80" height="40"></p>
                    <h4>SEPA Lastschrift</h4>
                  </label>
                </div>-->
                <div class="option-wrap col-6">
                  <label for="card" class="payment-select">
                    <p><input type="radio" id="card" value="card" v-model="personal_data.payment_option"></p>
                    <p><img src="../../assets/images/methods/card.png" width="80" height="40"></p>
                    <h4>Kreditkarte</h4>
                  </label>
                </div>
                <div class="option-wrap col-6">
                  <label for="sofort" class="payment-select">
                    <p><input type="radio" id="sofort" value="sofort" v-model="personal_data.payment_option"></p>
                    <p><img src="../../assets/images/methods/sofort.png" width="80" height="40"></p>
                    <h4>SOFORT</h4>
                  </label>
                </div>
              </div>

              <p class="alert alert-info" style="margin-top:0;margin-bottom:20px">Sie werden im Anschluss der Buchung zur Bezahlung geleitet.</p>

              <h3>Vertragliches</h3>

              <div class="form-wrap form-checkbox">
                <input type="checkbox" id="agb" v-model="personal_data.accepted_terms">
                <label for="agb">Ich habe die <router-link to="/terms" target="_blank">AGB</router-link> gelesen und akzeptiere diese.</label>
              </div>

              <div class="form-wrap form-checkbox">
                <input type="checkbox" id="privacy" v-model="personal_data.accepted_privacy">
                <label for="privacy">Ich habe die <router-link to="/privacy" target="_blank">Datenschutzbestimmungen</router-link> gelesen und akzeptiere diese.</label>
              </div>

              <p><a @click="book_now" class="button button-orange button-100">Jetzt kostenpflichtig buchen ›</a></p>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'new_reservation',
  data () {
    return {
      pickup_location: 1,
      success: false,
      locations: [],
      reservation: {},
      period: {
        completed: false,
        pickup_location: 1,
        pickup_date: "",
        pickup_hours: [],
        pickup_time: "08",
        return_location: 1,
        return_date: "",
        return_time: "16",
        return_hours: []
      },
      vehicle: {
        completed: false,
        name: "",
        category_id: "",
        details: {}
      },
      extras: {
        completed: false,
        insurance: "",
        kilometer: "",
        extras: [],
        pricing: {
          insurance: {},
          kilometer: {},
          extras: [],
          sum_net: "",
          sum_tax: "",
          sum_gross: ""
        }
      },
      personal_data: {
        completed: false,
        company: "",
        first_name: "",
        last_name: "",
        street: "",
        zip_code: "",
        city: "",
        email: "",
        phone: "",
        payment_option: "",
        accepted_terms: false,
        accepted_privacy: false,
        accepted_age: false,
        birth_date: ""
      },
      vehicle_categories: [],
      available_extras: {
        insurances: [],
        kilometer: [],
        extras: []
      },
      errors: [],
      user: {},
      user_login: false,
      tax_rate: 16
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    get_days_from_days(from, to) {
      var difference_in_time = new Date(from) - new Date(to);
      var difference_in_days = difference_in_time / (1000 * 3600 * 24);
      console.log(difference_in_days);
    },
    set_dates() {
      this.period.pickup_date = new Date();
      this.period.return_date = new Date();
      this.get_pickup_hours(new Date());
      this.get_return_hours(new Date());
    },
    get_price_by_weekday(day) {
      console.log(day);
      /*var price = ""
      if (day == 'monday') {
        price_monday
      } else if (day == 'tuesday') {
        price_tuesday
      } else if (day == 'wednesday') {
        price_wednesday
      } else if (day == 'thursday') {
        price_thursday
      } else if (day == 'friday') {
        price_friday
      } else if (day == 'saturday') {
        price_saturday
      } else if (day == 'sunday') {
        price_sunday
      }*/
    },
    check_home_params() {
      if (this.$route.query.home) {
        this.$store.commit('reset_reservation');
        this.pickup_location = this.$route.query.pickup_location;
        this.period.pickup_location = this.$route.query.pickup_location;
        this.period.pickup_date = new Date(this.$route.query.pickup_date);
        this.period.pickup_time = this.$route.query.pickup_time;
        this.period.return_location = this.$route.query.return_location;
        this.period.return_date = new Date(this.$route.query.return_date);
        this.period.return_time = this.$route.query.return_time;
        this.$router.replace({'query': null});
        this.period.completed = true;
        this.set_period();
      } else {
        this.set_dates();
      }
    },
    get_locations() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations', {})
      .then(response => {
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })
    },
    get_user() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.user_login = true;
        this.user = response.data.user;
        this.personal_data.company = response.data.user.company;
        this.personal_data.first_name = response.data.user.first_name;
        this.personal_data.last_name = response.data.user.last_name;
        this.personal_data.street_name = response.data.user.street_name;
        this.personal_data.street_number = response.data.user.street_number;
        this.personal_data.zip_code = response.data.user.zip_code;
        this.personal_data.city = response.data.user.city;
        this.personal_data.email = response.data.user.email;
        this.personal_data.phone_number = response.data.user.phone_number;
        this.personal_data.birth_date = response.data.user.birth_date;
      })
      .catch(error => {
        console.log(error);
        this.user_login = false;
      })
    },
    determine_step() {
      this.reservation = this.$store.getters.get_reservation;
      if (this.reservation.current_step == 2) {
        this.get_vehicle_categories();
      } else if (this.reservation.current_step == 3) {
        this.vehicle = this.reservation.vehicle;
        this.get_extras();
      }
    },
    get_vehicle_categories() {
      var from = moment(this.reservation.period.pickup_date).format("DD.MM.YYYY")+"-"+this.reservation.period.pickup_time+":00"
      var to = moment(this.reservation.period.return_date).format("DD.MM.YYYY")+"-"+this.reservation.period.return_time+":00"
      axios.get(process.env.VUE_APP_BASE_API+'/v1/vehicle_categories?location_id='+this.reservation.period.pickup_location+'&from='+from+'&to='+to, {})
      .then(response => {
        this.vehicle_categories = response.data.vehicle_categories;

        response.data.vehicle_categories.forEach(category => {

          var price = category.price_per_day;
          var duration = this.reservation.period.duration;

          var date = moment(this.reservation.period.pickup_date).format("d");

          if (duration < 2) {

            if (date == 1) {
              price = category.price_monday;
            } else if (date == 2) {
              price = category.price_tuesday;
            } else if (date == 3) {
              price = category.price_wednesday;
            } else if (date == 4) {
              price = category.price_thursday;
            } else if (date == 5) {
              price = category.price_friday;
            } else if (date == 6) {
              price = category.price_saturday;
            } else if (date == 0) {
              price = category.price_sunday;
            } else {
              price = category.price_per_day;
            }

          } else if (duration == 2) {

            if (category.price_ws_2) {
              if (date == 5) {
                price = category.price_lt_2*category.price_ws_2;
              } else if (date == 6) {
                price = category.price_lt_2*category.price_ws_2;
              } else {
                price = category.price_lt_2
              }
            } else {
              price = category.price_lt_2
            }

          } else if (duration >= 3 && duration < 5) {

            if (category.price_ws_3) {
              if (date == 5) {
                price = category.price_lt_3*category.price_ws_3;
              } else if (date == 6) {
                price = category.price_lt_3*category.price_ws_3;
              } else {
                price = category.price_lt_3
              }
            } else {
              price = category.price_lt_3;
            }

          } else if (duration >= 5 && duration < 7) {
            price = category.price_lt_5;
          } else if (duration >= 7 && duration < 14) {
            price = category.price_lt_7;
          } else if (duration >= 14 && duration < 30) {
            price = category.price_lt_14;
          } else if (duration >= 30) {
            price = category.price_lt_30;
          } else {
            price = category.price_per_day;
          }

          category.price_calculated = price;

        })

      })
      .catch(error => {
        console.log(error);
      })
    },
    get_extras() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/vehicle_categories/'+this.reservation.vehicle.name+'/extras', {})
      .then(response => {
        this.available_extras.insurances = [];
        this.available_extras.kilometer = [];
        this.available_extras.extras = [];
        response.data.extras.forEach(value => {
          console.log(value);
          if (value.feature.category == 'insurance') {
            this.available_extras.insurances.push(value);
            if (value.feature.price == "0.0") {
              this.extras.insurance = value.id;
              this.set_insurance(value);
            }
          }
          if (value.feature.category == 'kilometer') {
            this.available_extras.kilometer.push(value);
            if (value.feature.price == "0.0") {
              this.extras.kilometer = value.id;
              this.set_kilometer(value);
            }
          }
          if (value.feature.category == 'extra') {
            this.available_extras.extras.push(value);
          }
        })
      })
      .catch(error => {
        console.log(error);
      })
    },
    set_period() {
      var difference_in_time = this.period.return_date.setHours(this.period.return_time,0,0,0) - this.period.pickup_date.setHours(this.period.pickup_time,0,0,0);
      var difference_in_days = difference_in_time / (1000 * 3600 * 24);
      this.period.duration = Math.ceil(difference_in_days);
      this.period.pickup_date_formatted = moment(this.period.pickup_date).format("DD.MM.YYYY");
      this.period.return_date_formatted = moment(this.period.return_date).format("DD.MM.YYYY");
      this.$store.commit('add_period', this.period);
      this.reservation = this.$store.getters.get_reservation;
      this.period.completed = true;
      this.get_vehicle_categories();
    },
    set_vehicle_category(vehicle) {
      this.vehicle.name = vehicle.id;
      this.vehicle.details = vehicle;
      this.$store.commit('add_vehicle_category', this.vehicle);
      this.reservation = this.$store.getters.get_reservation;
      this.vehicle.completed = true;
      this.get_extras();
    },
    set_extras() {

      var sum_gross = 0.0;
      sum_gross = sum_gross + parseFloat(this.reservation.vehicle.details.price_calculated*this.reservation.period.duration);

      if (this.extras.pricing.insurance.price_type == 'once') {
        sum_gross = sum_gross + parseFloat(this.extras.pricing.insurance.price);
      } else {
        sum_gross = sum_gross + (parseFloat(this.extras.pricing.insurance.price)*this.reservation.period.duration);
      }
      //sum_gross = sum_gross + parseFloat(this.extras.pricing.insurance.price);

      if (this.extras.pricing.kilometer.price_type == 'once') {
        sum_gross = sum_gross + parseFloat(this.extras.pricing.kilometer.price);
      } else {
        sum_gross = sum_gross + (parseFloat(this.extras.pricing.kilometer.price)*this.reservation.period.duration);
      }
      //sum_gross = sum_gross + parseFloat(this.extras.pricing.kilometer.price);

      this.extras.pricing.extras.forEach(val => {
        if (val.price_type == 'once') {
          sum_gross = sum_gross + parseFloat(val.price);
        } else {
          sum_gross = sum_gross + (parseFloat(val.price)*this.reservation.period.duration);
        }
      });

      this.extras.pricing.sum_gross = parseFloat(sum_gross).toFixed(2);

      if (moment(this.reservation.period.return_date).format('YYYY') > 2020) {
        this.tax_rate = 19;
        this.extras.pricing.sum_net = parseFloat(this.extras.pricing.sum_gross/1.19).toFixed(2);
      } else {
        this.tax_rate = 16;
        this.extras.pricing.sum_net = parseFloat(this.extras.pricing.sum_gross/1.16).toFixed(2);
      }

      this.extras.pricing.sum_tax = parseFloat(this.extras.pricing.sum_gross-this.extras.pricing.sum_net).toFixed(2);

      this.get_days_from_days(this.reservation.period.return_date, this.reservation.period.pickup_date);
      if (this.extras.insurance) {
        if (this.extras.kilometer) {
          this.$store.commit('add_extras', this.extras);
          this.reservation = this.$store.getters.get_reservation;
          this.extras.completed = true;
        } else {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Bitte Kilometer auswählen',
          });
        }
      } else {
        this.$notify({
          group: 'alert',
          type: 'error',
          title: 'Bitte Versicherung auswählen',
        });
      }

    },
    book_now() {

      var items = [];

      items.push({
        name: "Fahrzeugmiete für "+this.reservation.period.duration+" Tage",
        price: parseFloat(this.reservation.vehicle.details.price_calculated*this.reservation.period.duration).toFixed(2)
      })

      if (this.extras.pricing.insurance.price_type == 'once') {
        items.push({
          name: "Versicherung ("+this.reservation.extras.pricing.insurance.name+")",
          price: parseFloat(this.reservation.extras.pricing.insurance.price).toFixed(2),
          description: this.reservation.extras.pricing.insurance.description
        })
      } else {
        items.push({
          name: "Versicherung ("+this.reservation.extras.pricing.insurance.name+")",
          price: parseFloat(this.reservation.extras.pricing.insurance.price*this.reservation.period.duration).toFixed(2),
          description: this.reservation.extras.pricing.insurance.description
        })
      }

      if (this.extras.pricing.kilometer.price_type == 'once') {
        items.push({
          name: "Kilometer ("+this.reservation.extras.pricing.kilometer.name+")",
          price: parseFloat(this.reservation.extras.pricing.kilometer.price).toFixed(2),
          description: this.reservation.extras.pricing.kilometer.description
        })
      } else {
        items.push({
          name: "Kilometer ("+this.reservation.extras.pricing.kilometer.name+")",
          price: parseFloat(this.reservation.extras.pricing.kilometer.price*this.reservation.period.duration).toFixed(2),
          description: this.reservation.extras.pricing.kilometer.description
        })
      }

      this.reservation.extras.pricing.extras.forEach(extra => {
        if (extra.price_type == 'once') {
          items.push({
            name: extra.name,
            price: parseFloat(extra.price).toFixed(2),
            description: extra.description
          })
        } else {
          items.push({
            name: extra.name,
            price: parseFloat(extra.price*this.reservation.period.duration).toFixed(2),
            description: extra.description
          })
        }
      })

      axios.post(process.env.VUE_APP_BASE_API+'/v1/reservations', {
        'email': this.personal_data.email,
        'pickup_date': this.reservation.period.pickup_date,
        'pickup_time': this.reservation.period.pickup_time,
        'return_date': this.reservation.period.return_date,
        'return_time': this.reservation.period.return_time,
        'pickup_location_id': this.reservation.period.pickup_location,
        'return_location_id': this.reservation.period.return_location,
        'company': this.personal_data.company,
        'first_name': this.personal_data.first_name,
        'last_name': this.personal_data.last_name,
        'street_name': this.personal_data.street_name,
        'street_number': this.personal_data.street_number,
        'zip_code': this.personal_data.zip_code,
        'city': this.personal_data.city,
        'phone_number': this.personal_data.phone_number,
        'birth_date': this.personal_data.birth_date,
        'accepted_terms': this.personal_data.accepted_terms,
        'accepted_privacy': this.personal_data.accepted_privacy,
        'accepted_age': this.personal_data.accepted_age,
        'payment_option': this.personal_data.payment_option,
        'category_id': this.reservation.vehicle.name,
        'res_items': items,
        'sum_net': this.reservation.extras.pricing.sum_net,
        'sum_tax': this.reservation.extras.pricing.sum_tax,
        'sum_gross': this.reservation.extras.pricing.sum_gross,
        'tax_rate': this.tax_rate
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        //this.$router.push('/');


        this.$store.commit('reset_reservation');
        this.reservation = this.$store.getters.get_reservation;
        this.$router.push('/reservations/'+response.data.reservation.uuid);
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Reservierung fehlgeschlagen',
            text: value
          });
        })
      })

    },
    change_step(step) {
      if (step == 2) {
        this.get_vehicle_categories();
      }
      if (step == 3) {
        this.get_extras();
      }
      this.$store.commit('change_step', step);
    },
    set_insurance(extra) {
      this.extras.pricing.insurance = {
        name: extra.feature.name,
        price: extra.feature.price,
        price_type: extra.feature.price_type,
        description: extra.feature.description
      }
    },
    set_kilometer(extra) {
      this.extras.pricing.kilometer = {
        name: extra.feature.name,
        price: extra.feature.price,
        price_type: extra.feature.price_type,
        description: extra.feature.description
      }
    },
    set_extrass() {
      this.extras.pricing.extras = []
      var available_extras = this.available_extras.extras;
      setTimeout(() => {
        var selected_extras = this.extras.extras;
        available_extras.forEach(val => {
          if (selected_extras.includes(val.id)) {
            this.extras.pricing.extras.push({
              name: val.feature.name,
              price: val.feature.price,
              price_type: val.feature.price_type,
              description: val.feature.description
            })
          }
        })
      }, 200)

    },
    get_pickup_hours(hour_day) {
      var hd = moment(hour_day).format("dddd").toLowerCase();
      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations/'+this.period.pickup_location+'/get_hours?hour_day='+hd, {})
      .then(response => {
        this.period.pickup_hours = response.data.business_hours;
      })
    },
    get_return_hours(hour_day) {
      var hd = moment(hour_day).format("dddd").toLowerCase();
      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations/'+this.period.return_location+'/get_hours?hour_day='+hd, {})
      .then(response => {
        this.period.return_hours = response.data.business_hours;
      })
    },
  },
  mounted () {
    this.check_home_params();
    this.get_locations();
    this.determine_step();
    this.get_user();
  },
  watch: {
    pickup_location: function(val) {
      this.period.pickup_location = val;
      this.period.return_location = val;
    },
    'period.pickup_date': function(val) {
      this.get_pickup_hours(val);
    },
    'period.return_date': function(val) {
      this.get_return_hours(val);
    },
  }
}
</script>

<style lang="scss">

.car-categories {
  padding: 0;

  li {
    border: 1px solid #f1f1f1;
    margin-bottom: 20px;
    list-style-type: none;
    padding: 20px;
    border-radius: 5px;

    .vehicle-image {
      margin: 0 30px 0 0;
      float: left;
    }

    .vehicle-body {
      float: left;
      width: calc(100% - 700px);

      h3 {
        margin: 15px 0 0 0;
        color: #444;

        small {
          margin-top: 4px;
          display: block;
          font-weight: 400;
          color: #888;
        }
      }

      p {
        margin: 15px 0 0 0;
        color: #888;
        font-size: 18px;
      }
    }

    .vehicle-cta {
      float: right;

      span {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        margin-right: 10px;
      }

      a {
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
}

.step-wizard {
  width: 100%;
  padding: 20px;
  background: #fff;
  border-bottom: 1px dotted #ccc;

  .step-wrap {
    padding: 20px;
    border-bottom: 4px solid transparent;
    position: relative;

    .step {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 24px;
      height: 24px;
      background: #f1f1f1;
      border-radius: 50%;
      padding: 3px 8px;
    }

    .change-step {
      position: absolute;
      right: 10px;
      top: 10px;
      background: #FF9000;
      color: #fff;
      padding: 2px 6px 6px 6px;
      border-radius: 3px;
      cursor: pointer;

      i {
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .change-step:hover {
      background: #EB8500;
    }
  }

  .active {
    background: #f9f9f9;
    border-bottom: 4px solid #FF9000;

    .step {
      background: #FF9000;
      color: #fff;
    }
  }

  h5 {
    margin: 0 0 0 40px;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin: 10px 0 0 40px;
    font-size: 16px;
  }
}

.wizard-body {
  padding: 30px;

  h2 {
    margin-top: 0;
    font-size: 30px;
    color: #FF9000;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #6E6E6E;
  }
}

.selected-vehicle {
  border: 1px solid #f1f1f1;
  text-align: center;
  padding: 30px;
  margin-right: 30px;
  border-radius: 10px;
}

.success-wrap {
  text-align: center;
  padding: 100px;

  h1 {
    color: #FF9000;
  }

  .selected-vehicle {
    width: 350px;
    margin: auto;
  }
}

.alert-info {
  color: #444;
  background-color: #f9f9f9;
  border: 1px solid #f1f1f1;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  font-size: 14px;
}

.alert-kaution {
  color: #004085;
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  position: relative;
  padding: 15px;
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  font-weight: 400;
}

.payment-select {

}
</style>
